var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 container mx-auto flex flex-col py-6 px-3"},[_c('h2',{staticClass:"text-3xl text-primary md:text-4xl font-medium mb-2"},[_vm._v(" "+_vm._s(_vm.config.loyalty.title ? _vm.config.loyalty.title[_vm.lang] : 'Voordeelkaarten')+" ")]),(_vm.showContent && _vm.$store.state.loyalty.vouchers.length > 0)?_c('p',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.config.loyalty.description[_vm.lang])+" ")]):_vm._e(),(_vm.contactLabels.length > 0)?_c('div',{staticClass:"mb-2"},_vm._l((_vm.contactLabels),function(label){return _c('span',{key:label,staticClass:"inline-flex items-center rounded-md text-white px-2 py-1 mr-2 text-medium font-medium bg-primary ring-1 ring-inset ring-gray-500/10"},[_vm._v(" "+_vm._s(label)+" ")])}),0):_vm._e(),(_vm.showContent && _vm.$store.state.loyalty.vouchers.length > 0)?_c('table',{staticClass:"table-auto"},[_c('thead',[_c('tr',[_c('th',{staticClass:"border-b px-1 sm:px-8 py-2 align-top text-left sm:table-cell",class:{
                    'hidden': _vm.config.loyalty.show_usage === true
                }},[_vm._v(" Soort ")]),(_vm.config.loyalty.show_usage !== false)?_c('th',{staticClass:"border-b px-1 sm:px-8 py-2 align-top text-left"},[_vm._v(" Code ")]):_vm._e(),_c('th',{staticClass:"border-b px-1 sm:px-8 py-2 align-top text-left sm:table-cell",class:{
                        'hidden': _vm.config.loyalty.show_usage === true
                    }},[_vm._v(" Geldigheid ")]),(_vm.config.loyalty.show_usage !== false)?_c('th',{staticClass:"border-b px-1 sm:px-8 py-2 align-top text-left"},[_vm._v(" Gebruikt ")]):_vm._e(),_c('th')])]),_vm._l((_vm.$store.state.loyalty.vouchers),function(voucher){return _c('tbody',{key:voucher.id},[_c('tr',[_c('td',{staticClass:"px-1 sm:px-8 py-2 align-top sm:table-cell",class:{
                'hidden': _vm.config.loyalty.show_usage === true
            }},[_c('h4',[_c('span',[_vm._v(" "+_vm._s(voucher.name)+"  ")])])]),(_vm.config.loyalty.show_usage !== false)?_c('td',{staticClass:"px-1 sm:px-8 py-2 align-top"},[(_vm.showCode(voucher))?_c('CopyText',{staticClass:"mt-1",attrs:{"text":voucher.code}}):(voucher.expired)?_c('span',[_c('i',[_vm._v(" niet meer beschikbaar ")])]):_c('span',[_c('i',[_vm._v(" nog niet beschikbaar ")])])],1):_vm._e(),_c('td',{staticClass:"px-1 sm:px-8 py-2 align-top sm:table-cell",class:{
                    'hidden': _vm.config.loyalty.show_usage === true
                }},[(voucher.expiryts && voucher.expired)?_c('span',[_vm._v(" vervallen op "+_vm._s(_vm._f("moment")(voucher.expiryts))+" ")]):(voucher.expiryts)?_c('span',[_vm._v(" geldig tot "+_vm._s(_vm._f("moment")(voucher.expiryts))+" ")]):_c('span',[_vm._v(" onbeperkt geldig ")])]),(_vm.config.loyalty.show_usage !== false)?_c('td',{staticClass:"px-1 sm:px-8 py-2 align-top text-left"},[(voucher.maxusages)?_c('span',[_vm._v(" "+_vm._s(voucher.totalused)+" van "+_vm._s(_vm.maxusages(voucher))+" ")]):_c('span',[_vm._v(" "+_vm._s(voucher.totalused)+" keer ")])]):_vm._e()])])})],2):(_vm.showContent)?_c('p',[_vm._v(" Er zijn geen persoonlijke voordeelkaarten gevonden. ")]):_vm._e(),(_vm.isLoading)?_c('div',[_c('font-awesome-icon',{staticClass:"text-primary block m-auto",attrs:{"icon":"spinner","spin":"spin","size":"3x"}})],1):_vm._e(),(_vm.error)?_c('div',[_c('p',[_vm._v(" Er ging iets mis. ")]),_c('p',[_vm._v(" Probeer het opnieuw of neem contact met ons op via "),_c('a',{attrs:{"href":("mailto:" + (_vm.config.email))}},[_vm._v(_vm._s(_vm.config.email))]),_vm._v(". ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }