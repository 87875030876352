<template>
    <div class="w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 container mx-auto flex flex-col py-6 px-3">
        <h2 class="text-3xl text-primary md:text-4xl font-medium mb-2">
            {{ config.loyalty.title ? config.loyalty.title[lang] : 'Voordeelkaarten' }}
        </h2>
        <p v-if="showContent && $store.state.loyalty.vouchers.length > 0" class="pb-2">
            {{ config.loyalty.description[lang] }}
        </p>
        <div v-if="contactLabels.length > 0" class="mb-2">
            <span v-for="label of contactLabels" v-bind:key="label"
             class="inline-flex items-center rounded-md text-white px-2 py-1 mr-2 text-medium font-medium bg-primary ring-1 ring-inset ring-gray-500/10">
                {{ label }}
            </span>
        </div>
        <table v-if="showContent && $store.state.loyalty.vouchers.length > 0" class="table-auto">
            <thead>
                <tr>
                <th class="border-b px-1 sm:px-8 py-2 align-top text-left sm:table-cell"
                    :class="{
                        'hidden': config.loyalty.show_usage === true
                    }">
                    Soort
                </th>
                <th v-if="config.loyalty.show_usage !== false"
                    class="border-b px-1 sm:px-8 py-2 align-top text-left">
                    Code
                </th>
                <th class="border-b px-1 sm:px-8 py-2 align-top text-left sm:table-cell"
                    :class="{
                            'hidden': config.loyalty.show_usage === true
                        }">
                    Geldigheid
                </th>
                <th v-if="config.loyalty.show_usage !== false"
                    class="border-b px-1 sm:px-8 py-2 align-top text-left">
                    Gebruikt
                </th>            
                <th>
                </th>
                </tr>
            </thead>
            <tbody class="" v-for="voucher of $store.state.loyalty.vouchers" v-bind:key="voucher.id">
                <tr>
                <td class="px-1 sm:px-8 py-2 align-top sm:table-cell" :class="{
                    'hidden': config.loyalty.show_usage === true
                }">
                    <h4>
                    <span>
                        {{ voucher.name }}&nbsp;
                    </span>
                    </h4>
                </td>
                <td v-if="config.loyalty.show_usage !== false"
                    class="px-1 sm:px-8 py-2 align-top">
                    <CopyText class="mt-1" :text="voucher.code" v-if="showCode(voucher)"/>
                    <span v-else-if="voucher.expired">
                        <i>
                            niet meer beschikbaar
                        </i>
                    </span>
                    <span v-else>
                        <i>
                            nog niet beschikbaar
                        </i>
                    </span>
                </td>
                <td class="px-1 sm:px-8 py-2 align-top sm:table-cell"
                    :class="{
                        'hidden': config.loyalty.show_usage === true
                    }">
                    <span v-if="voucher.expiryts && voucher.expired">
                        vervallen op {{ voucher.expiryts | moment }} 
                    </span>
                    <span v-else-if="voucher.expiryts">
                        geldig tot {{ voucher.expiryts | moment }} 
                    </span>
                    <span v-else>
                        onbeperkt geldig
                    </span>
                </td>
                <td v-if="config.loyalty.show_usage !== false"
                    class="px-1 sm:px-8 py-2 align-top text-left">
                    <span v-if="voucher.maxusages">
                        {{ voucher.totalused }} van {{ maxusages(voucher) }}
                    </span>
                    <span v-else>
                        {{ voucher.totalused }} keer
                    </span>
                </td>            
                </tr>
            </tbody>
        </table>
        <p v-else-if="showContent">
            Er zijn geen persoonlijke voordeelkaarten gevonden.
        </p>
        <div v-if="isLoading">
            <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary block m-auto"/>
        </div>
        <div v-if="error">
            <p>
                Er ging iets mis.
            </p>
            <p>
                Probeer het opnieuw of neem contact met ons op via <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </div>          
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CopyText from '../components/CopyText.vue'
const moment = require("moment")

function hasCommonItem(arr1, arr2) {
  return arr1.some(item => {
    return arr2.includes(item);
  });
}

export default {
  name: 'Ticketmatic',
  title: 'Mijn Voordeelkaarten',
  components: {
        CopyText
    },
  data() {
    return {
        isLoading: false,
        error: false,
    }
  },
  computed: {
      ...mapGetters(["config", "loyalty"]),
      showContent: function() {
          return this.isLoading === false && !this.error;
      },
      contactLabels: function() {
        if (!this.$auth.contactinfo.relationtypes || !this.config.labels) {
          return '';
        }

        const result = this.config.labels.filter(item => {
          return hasCommonItem(item.relationtypes, this.$auth.contactinfo.relationtypes);
        });

        if (!result) {
          return [];
        }

        const lang = this.config.locale || 'nl';
        return result.map(o => o.label[lang]);
      },
  },
  methods: {
      showCode(voucher) {
        return (voucher.code && !voucher.expired)
      },
      maxusages: function(voucher) {
          if (voucher.maxusages > 99999) {
              return 'onbeperkt';
          }
          return voucher.maxusages;
      },      
  },
  filters: {
    moment: function (date) {
        return moment(date).format('D-M-YYYY');
    }
  },
  async created() {
    if (this.$store.state.loyalty && this.$store.state.loyalty.vouchers.length > 0) {
        return; // do not refresh every mount
    }    

    this.isLoading = true;
    try {
        await this.$store.dispatch('setLoyalty')
    } catch (ex) {
        this.error = true;
    } finally {
        this.isLoading = false;
    }
  },
  async mounted () {
    this.$mixpanel.track('pageview', {
        distinct_id: this.$auth.user,
        account: this.config.shortname,
        page: "Loyalty",
    })
  },    
}
</script>