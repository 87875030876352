<template>
    <div class="mb-6 text-xl">                    
        <div>
            <div
                @click="copyText(text)"
                class="rounded h-6 leading-6 cursor-pointer px-1 inline-block transition"
                v-bind:class="{ copied: copied, notcopied: !copied }" 
                title="Klik om te kopieren"
            >
                <div class="flex items-center">
                    <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        class="w-5 h-5 mr-1"
                    >
                        <path :d="getPath()"></path>
                    </svg>
                    {{ text }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as clipboard from "clipboard-polyfill/text";
export default {
  data() {
    return {
        copied: false,
    }
  },
  props: {
    text: {
      type: String,
      required: true
    }
  },
  methods: {
      copyText: function(code) {
          clipboard.writeText(code);
          this.copied = true;
      },
      getPath: function() {
        return this.copied ? 'M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4'
                           : 'M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3'
      }
  }
}
</script>
<style lang="scss">
.copied {
    @apply text-green-900 bg-green-200;
}

.notcopied {
    @apply text-gray-900 bg-gray-200 hover:bg-gray-800 hover:text-white;
}
</style>